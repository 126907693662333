import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { initialize as initializeAuthService } from '@seft/auth-service';
import { initialize as initializeFirestore } from '@seft/firestore-service';
import { reset } from '@seft/design-tokens/assets/css/reset';

/**
 * Inject our imported CSS reset values, this allows us to share these values with our web components as they do not
 *  support the @import context for CSS within a component and esbuild defaults to global imports for CSS files which
 *  cannot pierce the shadow DOM.
 */
document.head.insertAdjacentHTML('beforeend', `<style>@layer reset { ${reset} }</style>`);

/**
 * @todo should this be stored directly in the code base?
 */
const firebaseConfig = {
  apiKey: 'AIzaSyAjJiHh2RSUSC0JheBxc-02Hoc3oA9mK04',
  authDomain: 'website-1f930.firebaseapp.com',
  databaseURL: 'https://website-1f930-default-rtdb.firebaseio.com',
  projectId: 'website-1f930',
  storageBucket: 'website-1f930.appspot.com',
  messagingSenderId: '1022924445785',
  appId: '1:1022924445785:web:03543f55bfd71fad8eb948',
  measurementId: 'G-82FQV1QCQ9',
};
const app = initializeApp(firebaseConfig);
getAnalytics(app);
initializeFirestore(app, 'seft-website');

async function init() {
  try {
    await initializeAuthService();
    const registry = await import('./pages/registry');
    registry.initialize();
    await import('./elements');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}
void init();
